import { IUserData } from "@gnv/resevo-react-lib";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import useWindowSize from "../../hooks/useWindowSize";
import SplashPage from "../../pages/splashPage/SplashPage";
import UserProfileModal from "../userProfileModal/UserProfileModal";
import Header, { DrawerHeader } from "./drawer/Header/Header";
import ResevoDrawer from "./drawer/ResevoDrawer";
import MenuItemVisualizer from "./menuItemVisualizer/MenuItemVisualizer";
import "./sideBarMenu.scss";
import TopMenuOpen from "./topMenu/TopMenuOpen";
import UserAvatar from "./userAvatar/UserAvatar";

type ISideMenuBar = {
  onChangeLanguage: (lang: string) => void;
  children: JSX.Element | ReactNode;
  currentUser: IUserData;
};

const isRootPage = () => location.pathname === "/";

export default function SideMenuBar({
  currentUser,
  onChangeLanguage,
  children,
}: ISideMenuBar): any {
  const windowSize = useWindowSize();
  useCurrentLanguage();
  const [openModalUser, setOpenModalUser] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const isExternalUser =
    currentUser?.userAccountType && currentUser?.userAccountType === "EXT_USER";

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    // clear cart uid in order to prevent an undesired situation in pilet booking
    localStorage.clear();

    window.location.replace("/logout?oidc=true");
  };

  if (useLocation().pathname === "/welcome") {
    return children;
  }
  React.useEffect(() => {
    document.addEventListener("keydown", handleEscFunc, false);
  }, []);
  const handleEscFunc = (event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#EEF0F3",
      }}
    >
      <CssBaseline />
      <ResevoDrawer
        variant="permanent"
        open={open}
        onClose={() => setOpen(false)}
        className={
          !open
            ? "drawer-closed"
            : windowSize.width < 1521
            ? "drawer-over-content"
            : "drawer-docked"
        }
        sx={{ m: 2, mr: 0.875 }}
      >
        <Header
          isExternalUser={isExternalUser}
          handleToggleDrawer={handleToggleDrawer}
          open={open}
        />
        <ListItem
          sx={{ p: 0, mx: open ? "20px" : "0", width: "auto" }}
          data-testid="settings-dialog"
        >
          <UserAvatar
            handleOpenModalUser={() => setOpenModalUser(true)}
            currentUser={currentUser}
            open={open}
            logout={logout}
          />
        </ListItem>
        <Divider className="custom-list-item-divider" />
        {open && <TopMenuOpen />}
        <Divider className="custom-list-item-divider" />
        <MenuItemVisualizer open={open} />
        <Divider
          sx={{
            mx: open ? 3 : "6px",
            background: "white",
          }}
        />
      </ResevoDrawer>
      <Box component="main" className="main-drawer-container-box">
        <DrawerHeader />
        {isRootPage() && (
          <SplashPage
            userAccountType={currentUser?.userAccountType}
            username={currentUser?.name}
          />
        )}
        {children}
        <UserProfileModal
          currentUser={currentUser}
          logout={logout}
          open={openModalUser}
          handleOpen={() => setOpenModalUser(true)}
          handleClose={() => setOpenModalUser(false)}
          onChangeLanguage={onChangeLanguage}
        />
      </Box>
    </Box>
  );
}
