import React from "react";
import "./splashPageCard.scss";
import {Typography} from "@mui/material";
import {Button} from "@gnv/resevo-react-lib";
import MailIcon from "@mui/icons-material/Mail";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogoResevo from "../../static/svgs/logo_resevo.svg";
import LogoResevoADV from "../../static/svgs/logo_resevo_adv.svg";
import Box from "@mui/material/Box";
import {Localizer} from "../../i18n/localizer";

interface SplashPageCardProps {
  userName: string;
  userAccountType: string;
}

const SplashPageCard: React.FC<SplashPageCardProps> = ({
  userName,
  userAccountType,
}) => {
  const isExternalUser = userAccountType === "EXT_USER";

  return (
    <Box className="splash-card-container">
      <Box
        className="logo-container"
        sx={{
          textAlign: isExternalUser ? "center" : "left",
        }}
      >
        {isExternalUser ? (
          <Box
            component="img"
            src={LogoResevoADV}
            alt="logo"
            sx={{ position: "relative", left: "-10px" }}
          />
        ) : (
          <Box component="img" src={LogoResevo} alt="logo" />
        )}
      </Box>
      <Box className="header">
        <Typography variant="h1">
          {Localizer.getInstance().translate("welcome")}
        </Typography>
      </Box>
      <Box className="user-name">
        <Typography variant="h2">{userName}</Typography>
      </Box>
      <Box className="divider" />
      <Box className="help-text">
        <Typography variant="body1">
          {Localizer.getInstance().translate("need-help")}
        </Typography>
      </Box>

      <Box className="button-container">
        <Button
          variant="text"
          startIcon={<MailIcon color="primary" fontSize="small" />}
          sx={{
            fontSize: "14px",
            fontWeight: 500,
          }}
          color="primary"
        >
          {Localizer.getInstance().translate("support-contact")}
        </Button>
      </Box>
      <Box className="button-container-bottom">
        <Button
          variant="text"
          startIcon={<ContactSupportIcon color="primary" fontSize="small" />}
          sx={{
            fontSize: "14px",
            fontWeight: 500,
          }}
          color="primary"
        >
          {Localizer.getInstance().translate("use-guide")}
        </Button>
      </Box>
    </Box>
  );
};
export default SplashPageCard;
