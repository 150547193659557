import React, { FC, useEffect, useMemo, useState } from "react";
import {
  OperationsType,
  PermissionProvider,
  REACT_APP_IDLE_MINUTES,
  REACT_APP_KEEP_ALIVE_MINUTES,
  REACT_APP_KEEP_ALIVE_URL,
  UserSessionTimer,
} from "@gnv/resevo-libportal-web";
import { resevoTheme as gnvTheme } from "@gnv/resevo-react-lib";
import { ThemeOptions, useTheme } from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import * as locales from "@mui/material/locale";

import { Localizer } from "../../i18n/localizer";
import {
  DEFAULT_LOGOUT_URL,
  KEEP_ALIVE_IDLE_MINUTES_INTERVAL,
  KEEP_ALIVE_MINUTES_INTERVAL,
} from "../../constants/envConstants";
import userService from "../../services/UserService";
import { LayoutProps, useAction } from "piral-core";
import { ConfirmProvider } from "material-ui-confirm";
import SideMenuBar from "../../components/sideMenuBar/SidemenuBar";

import gnvFavcion from "../../static/favicons/favicon.ico";

export type SupportedLocales = keyof typeof locales;
const WELCOME_PAGE = "/welcome";
const keepAliveMinutes = !!REACT_APP_KEEP_ALIVE_MINUTES
  ? parseInt(REACT_APP_KEEP_ALIVE_MINUTES)
  : KEEP_ALIVE_MINUTES_INTERVAL;
const idleMinutes = !!REACT_APP_IDLE_MINUTES
  ? parseInt(REACT_APP_IDLE_MINUTES)
  : KEEP_ALIVE_IDLE_MINUTES_INTERVAL;

const DefaultLayout: FC<LayoutProps> = ({ children }) => {
  const [locale, setLocale] = useState<SupportedLocales>(
    Localizer.getInstance().getCurrentLanguage() as SupportedLocales
  );
  const handleLocaleChange = (lang: SupportedLocales) => setLocale(lang);
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => extendTheme(gnvTheme as ThemeOptions, locales[locale]),
    [locale, theme]
  );
  const [operations, setOperations] = useState<OperationsType>();
  const [userProfile, setUserProfile] = useState<any>();
  const setData = useAction("writeDataItem" as any);
  const disableAppMenu = useAction("disableAppMenu");
  const enableAppMenu = useAction("enableAppMenu");

  useEffect(() => {
    //set favicon
    const faviconElement: any = document.getElementById("favicon");
    faviconElement.href = gnvFavcion;
    const getUserProfile = async () => {
      disableAppMenu();
      try {
        const response = await userService.getUserProfile();

        response && enableAppMenu();
        setOperations(response.data.operations);
        setUserProfile(response.data);
        setData("userProfile", response.data);
      } catch {
        enableAppMenu();
      }
    };
    if (location.href.indexOf(WELCOME_PAGE) == -1) {
      getUserProfile();
    }
  }, []);

  return (
    <>
      <UserSessionTimer
        keepAliveUrl={REACT_APP_KEEP_ALIVE_URL}
        keepAliveMinutes={keepAliveMinutes}
        idleMinutes={idleMinutes}
        logoutUrl={DEFAULT_LOGOUT_URL}
      />
      <CssVarsProvider theme={themeWithLocale}>
        <PermissionProvider userOperations={operations}>
          <ConfirmProvider
            defaultOptions={{ dialogProps: { disableEnforceFocus: true } }}
          >
            <SideMenuBar
              currentUser={userProfile}
              onChangeLanguage={handleLocaleChange}
            >
              {children}
            </SideMenuBar>
          </ConfirmProvider>
        </PermissionProvider>
      </CssVarsProvider>
    </>
  );
};

export default DefaultLayout;
