import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useGlobalState } from "piral-core";
import React from "react";
import { useHistory } from "react-router-dom";
import { IListMenuItem } from "../../../models/IListMenuItem";
type TResevoSideSubMenuItemProps = {
  index: number;
  elem: IListMenuItem;
  activeMenu: string;
  handleMenuToggle: (event: React.MouseEvent<HTMLElement>) => void;
};

const ResevoSideSubMenuItem = ({
  index,
  elem,
  activeMenu,
  handleMenuToggle,
}: TResevoSideSubMenuItemProps) => {
  const history = useHistory();
  const menuDisabled = useGlobalState((state) => state.menuDisabled);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (menuDisabled) return;
    history.push(`${elem.route}`);
    handleMenuToggle(event);
  };
  return (
    <div className="list-sub-item-with-icon">
      <ListItem
        key={`${elem.route}${index}`}
        className="submenu-item"
        disablePadding
        selected={elem.route === activeMenu}
        onClick={handleClick}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        disabled={menuDisabled}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {elem.icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            py: 1,
            my: 0,
          }}
          primary={
            <Typography
              align="center"
              sx={{
                lineHeight: 2,
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              {elem.name}
            </Typography>
          }
        />
      </ListItem>
    </div>
  );
};

export default ResevoSideSubMenuItem;
