import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { OpenMenuGnvLogo, OpenMenuResevoLogo } from "@gnv/resevo-icons";
import { Divider, IconButton, ListItem, ListItemIcon } from "@mui/material";
import Box from "@mui/material/Box";


import { styled } from "@mui/material/styles";

type THeaderProps = {
  handleToggleDrawer: () => void;
  isExternalUser: boolean;
  open: boolean;
};
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const Header = ({ open, isExternalUser, handleToggleDrawer }: THeaderProps) => {
  return (
    <DrawerHeader
      sx={{
        display: "flex",
        justifyContent: open ? "center" : "space-between",
      }}
    >
      {!open && (
        <>
          <ListItem
            key="icon-item-key"
            sx={{ justifyContent: "center", pt: 2, pb: 2, px: 1 }}
            onClick={handleToggleDrawer}
          >
            <ListItemIcon className="menu-toggle-icon">
              <MenuIcon />
            </ListItemIcon>
          </ListItem>
          <Divider className="custom-list-item-divider" />
        </>
      )}
      {open && (
        <div style={{ width: "100%" }}>
          <ListItem
            key="icon-item-key"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2.5,
            }}
          >
            <Box
              style={{
                textDecoration: "none",
                height: "17px",
              }}
            >
              {isExternalUser ? (
                <OpenMenuGnvLogo viewBox={"0 0 63 17"} sx={
                  { width: "63px", height: "17px" }} />
              ) : (
                <OpenMenuResevoLogo viewBox={"0 0 141 17"} sx={
                  { width: "141px", height: "17px" }
                } />
              )}
            </Box>
            <ListItemIcon
              className="menu-toggle-icon"
              onClick={handleToggleDrawer}
            >
              <IconButton>
                <MenuIcon />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          <Divider className="custom-list-item-divider" />
        </div>
      )}
    </DrawerHeader>
  );
};

export default Header;
