import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import { useGlobalState } from "piral-core";
import React from "react";
import { IListMenuItem } from "../../../models/IListMenuItem";
import ISubmenuOpen from "../../../models/ISubMenuOpen";
type TMenuOpenItemProps = {
  elem: IListMenuItem;
  openState: ISubmenuOpen;
  handleMenuToggle: (event: React.MouseEvent<HTMLElement>) => void;
  renderSubmenuOpenEntry: (elem: IListMenuItem, index: number) => void;
};

const MenuOpenItem = ({
  elem,
  handleMenuToggle,
  openState,
  renderSubmenuOpenEntry,
}: TMenuOpenItemProps) => {
  const menuDisabled = useGlobalState((state) => state.menuDisabled);

  return (
    <React.Fragment>
      <ListItem
        sx={{ mx: 2.5, my: 0.25, height: 40, width: "auto" }}
        data-key={elem.key}
        onClick={handleMenuToggle}
        disabled={menuDisabled}
        className={openState[elem?.key] ? "item-opened" : "item-closed"}
      >
        <ListItemText
          className="menu-open-category-text"
          sx={{ color: "#0047BA" }}
          primary={elem.name}
        />
        {openState[elem.key] ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </ListItem>
      <Collapse
        className="sub-menu-container"
        in={openState[elem.key]}
        timeout="auto"
        unmountOnExit
      >
        <List component="ul" className="main-menu-submenu" disablePadding>
          {elem.children.map((subElement, subIndex) => {
            return renderSubmenuOpenEntry(subElement, subIndex);
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default MenuOpenItem;
