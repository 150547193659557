enum RoutesEnum {
  HOME = "/",
  WELCOME_PAGE = "/welcome",
  OPERATIONAL_VARIATIONS = "/utilities/operational-variations",
  OPERATIONS_SUMMARY = "/utilities/operations-summary",
  ACCOUNTING_SUMMARY = "/utilities/accounting-summary",
  MOVEMENTS_LIST = "/utilities/movements-list",
  BOOKING_EMIT = "/booking/emit",
  BOOKING_EDIT = "/booking/edit",
  BO_CUSTOMER_CARE = "/back-office/customer-care",
  BOOKING_CALENDAR = "/booking/calendar",
  CHECKIN = "/check-in-boarding-pax/check-in",
  PREPAID_AGENCY_INFO = "/booking/agency-prepaid-headers/agency-info/",
}

export default RoutesEnum;
