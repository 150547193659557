import { Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useGlobalState } from "piral-core";
import React from "react";
import { useHistory } from "react-router-dom";
import { IListMenuItem } from "../../../models/IListMenuItem";
type TMenuClosedItemProps = {
  index: number;
  elem: IListMenuItem;
  activeMenu: string;
  handleMenuToggle: (event: React.MouseEvent<HTMLElement>) => void;
};

function MenuClosedItem({
  index,
  elem,
  activeMenu,
  handleMenuToggle,
}: TMenuClosedItemProps) {
  const history = useHistory();
  const menuDisabled = useGlobalState((state) => state.menuDisabled);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (menuDisabled) return;
    history.push(`${elem.route}`);
    handleMenuToggle(event);
  };
  return (
    <div>
      <ListItem
        key={`${elem.route}${index}`}
        dense
        className="list-item-with-icon"
        selected={elem.route === activeMenu}
        onClick={handleClick}
        disabled={menuDisabled}
        data-testid={`route-${elem.route}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: 1,
          pr: 0.5,
          pl: 0.5,
          minWidth: "72px",
        }}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "10px",
            fontWeight: 500,
          }}
          style={{ minWidth: "40px" }}
          className="menu-closed-item-icon"
        >
          {elem.icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            mt: 0,
          }}
          primaryTypographyProps={{
            variant: "body3",
            whiteSpace: "normal",
            textAlign: "center",
            sx: { fontSize: "10px", fontWeight: "500" },
          }}
          primary={elem.name}
        />
      </ListItem>
      <Divider className="custom-list-item-divider" />
    </div>
  );
}

export default MenuClosedItem;
