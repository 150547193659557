import React from "react";
import MuiDrawer from "@mui/material/Drawer";

import { styled } from "@mui/material/styles";
const drawerWidth = 289;
const openedMixin = (theme) => ({
  width: drawerWidth,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shortest,
  }),
  width: 72,
  "& .MuiListItemText-root span": {
    maxWidth: "68px",
  },
});
const ResevoDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<any>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default ResevoDrawer;
