import React from "react";
import { ListItem, Box, Typography } from "@mui/material";
import { useHistory } from "react-router";
import HomeIcon from "@mui/icons-material/Home";

const TopMenuOpen = () => {
  const history = useHistory();
  const returnToHomePage = () => {
    history.push("/");
  };
  return (
    <div>
      <ListItem
        sx={{
          p: 0,
          mx: open ? "20px" : "0",
          width: "auto",
          height: "40px",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          columnGap={1.5}
          ml={"16px"}
          onClick={returnToHomePage}
        >
          <HomeIcon sx={{ color: "#FFF" }} />
          <Typography color="white" variant="body3">
            Home
          </Typography>
        </Box>
      </ListItem>
    </div>
  );
};

export default TopMenuOpen;
