import { ListItem, ListItemText } from "@mui/material";
import { useGlobalState } from "piral-core";
import React from "react";
import { Link } from "react-router-dom";
import { IListMenuItem } from "../../../models/IListMenuItem";

type TMenuOpenItemWithoutChildrenProps = {
  index: number;
  elem: IListMenuItem;
  handleMenuToggle: (event: React.MouseEvent<HTMLElement>) => void;
};

const MenuOpenItemWithoutChildren = ({
  elem,
  handleMenuToggle,
  index,
}: TMenuOpenItemWithoutChildrenProps) => {
  const menuDisabled = useGlobalState((state) => state.menuDisabled);
  return (
    <div key={index} className="list-item-with-icon">
      <Link
        key={`${elem.route}${index}`}
        style={{
          textDecoration: "none",
        }}
        to={`${elem.route}`}
      >
        <ListItem
          disabled={menuDisabled}
          data-key={elem.key}
          onClick={handleMenuToggle}
        >
          <ListItemText onClick={handleMenuToggle} primary={elem.name} />
        </ListItem>
      </Link>
    </div>
  );
};

export default MenuOpenItemWithoutChildren;
