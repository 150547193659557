import React from "react";
import MenuOpenItemWithoutChildren from "../../SideMenuItem/menuOpenItem/MenuOpenItemWithoutChildren";
import { Restricted } from "@gnv/resevo-libportal-web";
import ResevoSideSubMenuItem from "../../SideMenuItem/menuOpenItem/ResevoSideSubMenuItem";
import { IListMenuItem } from "../../../models/IListMenuItem";
import { useLocation } from "react-router";
import { useGlobalState } from "piral";
import ISubmenuOpen from "../../../models/ISubMenuOpen";
import MenuClosedItem from "../../SideMenuItem/menuClosedItem/MenuClosedItem";
import MenuOpenItem from "../../SideMenuItem/menuOpenItem/MenuOpenItem";
import { getPiletClients, getPiletsByCategories } from "../sidebarMenuElements";
import "../sideBarMenu.scss";

const MenuItemVisualizer = ({ open }) => {
  const menuDisabled = useGlobalState((state) => state.menuDisabled);
  const [openState, setOpenState] = React.useState<ISubmenuOpen>({
    home: true,
  });
  const handleMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    if (menuDisabled) return;
    const theKey = String(event.currentTarget.dataset.key);
    const newOpen = {
      ...openState,
      [theKey]: !openState[theKey],
      isMenuOpen: open,
    };
    setOpenState(newOpen);
  };
  //router
  const activeMenu = useLocation().pathname;
  //render sub menu entry child
  const renderSubmenuOpenEntry = (elem: IListMenuItem, index: number) => {
    return (
      <Restricted to={elem.operations} key={index}>
        <ResevoSideSubMenuItem
          elem={elem}
          index={index}
          activeMenu={activeMenu}
          handleMenuToggle={handleMenuToggle}
        />
      </Restricted>
    );
  };
  //render menu entry menu closed
  const renderMenuClosedEntry = (elem: IListMenuItem, index: number) => {
    return (
      <Restricted to={elem?.operations} key={index}>
        <MenuClosedItem
          elem={elem}
          index={index}
          activeMenu={activeMenu}
          handleMenuToggle={handleMenuToggle}
        />
      </Restricted>
    );
  };
  //render entry menu open
  const renderMenuOpenEntry = (elem: IListMenuItem, index: number) => {
    if (elem?.children) {
      return (
        <Restricted to={elem?.operations} key={"menu-entry-" + index}>
          <MenuOpenItem
            elem={elem}
            handleMenuToggle={handleMenuToggle}
            openState={openState}
            renderSubmenuOpenEntry={renderSubmenuOpenEntry}
          />
        </Restricted>
      );
    }

    return (
      <MenuOpenItemWithoutChildren
        elem={elem}
        handleMenuToggle={handleMenuToggle}
        index={index}
      />
    );
  };
  return (
    <div className="list-menu-items-container drawer">
      {open &&
        getPiletsByCategories().map((elem: IListMenuItem, index) => {
          return renderMenuOpenEntry(elem, index);
        })}
      {!open &&
        getPiletClients().map((elem, index) => {
          return renderMenuClosedEntry(elem, index);
        })}
    </div>
  );
};
export default MenuItemVisualizer;
